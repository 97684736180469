<template>
  <div class="seller_table">
    <div class="d-flex justify-content-between my-3 mx-2 pt-1 heading-back">
      <p class="mb-0 seller_heading">Seller Warehouse and Channel Stock Distribution</p>
      <span type="submit" @click="goToStock()">
        <img src="../../assets/SVG/Back.svg" width="60px">
      </span>
    </div>
    <b-card class="m-2 ">
      <w-table
        :tableData="tabledata"
        @imageeEvent="event($event)"
        @resendRowDataEvent="openResend($event)"
        @deleteRowDataEvent="openConfirmationModal($event)"
      ></w-table>
    </b-card>

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import ReportsTable from "../../widgets/ReportsTable.vue";
import Modal from "../../widgets/ModalWidget";
import { getSellerDetail } from "../../Service/InventoryService";
export default {
  components: {
    "w-table": ReportsTable,
    Modal,
  },
  props: ["productId"],
  data() {
    return {
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      tabledata: {
        fields: [
          {
            key: "warehouseName",
            label: "Warehouse Name",
          },
          {
            key: "sellableQuantity",
            label: "Sellable Stock",
          },
          {
            key: "badQuantity",
            label: "Bad Stock",
          },
          {
            key: "reservedQuantity",
            label: "Reserved Stock",
          },
          {
            key: "totalstock",
            label: "Total Stock",
          },
          {
            key: "seller_field",
            label: "Stock On Channel",
          },
        ],

        items: [
          {
            // showSubheaderIcon: true,
            // stockes : ["Channel-1","Channel-2","Channel-3","Channel-4","Channel-5","Channel-6","Channel-7"]
          },
          // {
          //   warehouse: "Bangalore-North-1",
          //   sellable: 20,
          //   reserved: 0,
          //   amazon: 20,
          //   flipkart: 11,
          //   stockes : [45,60,77,22,96]
          // },
          // {
          //   warehouse: "Bangalore-North-1",
          //   sellable: 20,
          //   reserved: 0,
          //   amazon: 30,
          //   flipkart: 33,
          //   stockes : [45,60,77]
          // },
        ],
      },
    };
  },
  created() {
    this.getSellerDetail();
  },

  methods: {
    goToStock() {
      this.$emit("backfromseller", false);
    },

    getSellerDetail() {
      // console.log(this.productId)
      getSellerDetail(this.productId, this.companyID)
        .then((res) => {
          const resp = res.response;
          if (resp != "") {
            this.tabledata.items = resp.map((el) => {
              return {
                ...el,
                totalstock: el.reservedQuantity + el.sellableQuantity,
              };
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>
<style>
.seller_table .table thead th {
  font-family: "proxima-nova", sans-serif;
  color: #1b1c1d;
  font-size: 13px;
  padding-left: 10px;
  text-align: center;
}
.seller_table .table tbody tr{
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
.seller_table .table tbody td {
  font-family: "proxima-nova", sans-serif;
  font-size: 13px;
  color: #1b1c1d;
  padding: 15px 0 15px 10px;
  text-align: center;
}

.seller_table .table tbody tr:nth-child(1) td:last-child {
  font-weight: bold;
}
.seller_table .table tbody td img {
  width: 20px;
}
</style>
<style scoped>
.seller_heading { 
  font-family: "roboto-bold";
  font-size: 16px;
  color: #4A4A4A;
}
@media screen and (max-width: 575px){
  .heading-back{
    flex-direction: column-reverse !important;
    padding-top: 0 !important;
  }
  .seller_heading{
    font-size: 12px !important;
    margin-top: 10px;
  }
  .heading-back span{
    text-align: right !important;
  }
}
</style>
