<template>
  <div class="mt-3 listing-inventory">
    <!-- this is for upload template page -->
    <div v-if="isUploadTemplatePage">
      <w-upload-listing
        @goBackToPrevPage="goBackToPreviousPage($event)"
      ></w-upload-listing>
    </div>
    <!-- This is for listing status page -->
    <div v-else>
      <!-- <span class="ml-3 summary_refresh_text"
        >Last Updated On: {{ lastUpdated ? lastUpdated : "-" }}</span
      >&nbsp;&nbsp;<span class="summary_refresh">
        <img
          style="cursor:pointer"
          @click="loadData()"
          src="../../assets/refresh.png"
          width="15px"
        />
      </span> -->

      <div v-if="isPermission('allow_view_listing_status')">
        <b-card class="cards m-2 listing-table listing-status-table">
          <w-table :tableData="tabledata"></w-table>
        </b-card>
      </div>
      <!-- filters -->
      <b-card class="cards m-2 inventory-filter" style="padding:1px" v-if="is_filter_open">
          <img
            class="mt-3 close-filter-icon"
            src="../../assets/CloseModel.svg"
            width="15px"
            @click="openFilter"
          />
        <div class="row align-items-end">
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Channels'"
              :rootOptions="channelList"
              :selectedValue="selectedChannelList"
              @selectedField="selectedChannel($event)"
            />
          </div>
          <div class="col-md-3">
            <!-- <label class="tree-label ml-2">SKU</label> -->
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="sku"
              :labelStyle="'register-label'"
              :fieldName="'SKU'"
              :labelName="'SKU'"
              @textInputChange="getSku($event)"
            />
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Mapping Status'"
              :rootOptions="mappingList"
              :selectedValue="selectedMappingList"
              @selectedField="selectedMappingStatus($event)"
            />
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Product Status'"
              :rootOptions="productStatus"
              :selectedValue="selectedProductList"
              @selectedField="selectedProductStatus($event)"
            />
          </div>
        </div>
        <!-- <div class="row justify-content-end mx-1">
          <div class="">
            <button class="search" @click="getListingProduct()">Filter</button>
          </div>
        </div> -->
      </b-card>

      <div class="m-2 listing-table">
        <listing-status-table
          :tableData="listingStatusTableProp"
          @openAddModalEvent="openAddModal($event)"
          @openAliasModalEvent="openAliasModal($event)"
          @openBundleEvent="openBundle($event)"
          @getSelectedPerPageEvent="getSelectedPerPage($event)"
          @getSelectedPageEvent="getSelectedPage($event)"
          @goToUploadTemplate="goToUploadpage($event)"
          @downloadListingTemplate="downloadTemplate($event)"
          :isListingStatus="showTemplateBtns"
          :searchListingStatus="true"
          @openFilterEvent="openFilter"
        ></listing-status-table>
      </div>
    </div>

    <!-- mark bundle modal -->
    <!-- <b-modal
      header-class="mark_bundle_modal"
      id="modal-centerr"
      centered
      title="Mark As Bundle"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      hide-footer="true"
    >
      <p class="mb-0">
        <a
          >JELSET-1</a
        >
      </p>
      <p>
        <a
          >Terracota Jwellery Set</a
        >
      </p>
      <p
        style="font-size: 14px; font-weight:600; color:#1b1c1d"
      >
        Aliases:
      </p>
      <div class="row">
        <div class="col-md-5">
          <label style="font-size: 14px; color:#1b1c1d"
            >Flipkart Store 1</label
          >
        </div>
        <div class="col-md-4">
          <a
            href="#"

            >Selwh43</a
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label style="font-size: 14px; color:#1b1c1d"
            >Amazon India Vezza</label
          >
        </div>
        <div class="col-md-4">
          <a
            href="#"

            >AZ-Jel-33</a
          >
        </div>
      </div>
      <div style="border:1px solid rgba(0, 0, 0, 0.2); padding:0 12px">
        <div class="row" style="border-bottom:1px solid rgba(0, 0, 0, 0.2);">
          <div class="col-md-6 pb-0 pt-1">
            <label
              class="ml-3"
              style="font-family: Roboto; font-size: 14px; font-weight:600; color:#1b1c1d"
            >
              Product SKU
            </label>
          </div>
          <div class="col-md-6 pb-0 pt-1">
            <label
              class="ml-4"
              style="font-family: Roboto; font-size: 14px; font-weight:600; color:#1b1c1d"
            >
              Quantity
            </label>
          </div>
        </div>
        <div class="row">
          <w-text-input
            :fieldType="'text'"
            v-model="sellerId"
            :className="'w-50 col-md-5 pt-3'"
            :disabled="false"
            :defaultValue="'AZ-09'"
            :labelStyle="'register-label'"
            @textInputChange="getSellerId($event)"
          />
          <w-text-input
            :fieldType="'text'"
            v-model="sellerId"
            :className="' col-md-5 pt-3'"
            :disabled="false"
            :defaultValue="'2'"
            :labelStyle="'register-label'"
            @textInputChange="getSellerId($event)"
          />
        </div>
        <div class="row">
          <w-text-input
            :fieldType="'text'"
            v-model="sellerId"
            :className="'w-50 col-md-5 pt-1'"
            :disabled="false"
            :defaultValue="'AZ-09'"
            :labelStyle="'register-label'"
            @textInputChange="getSellerId($event)"
          />

          <w-text-input
            :fieldType="'text'"
            v-model="sellerId"
            :className="' col-md-5 pt-1'"
            :disabled="false"
            :defaultValue="'1'"
            :labelStyle="'register-label'"
            @textInputChange="getSellerId($event)"
          />
          <span style="margin: 10px 20px;">
            <img src="../../assets/add.png" />
          </span>
        </div>
      </div>
      <div class="row mt-3 text-center">
        <div class="col-md-6">
          <b-button
            type="submit"
            class="save mark_bundle_save"
            variant="primary"
            color="none"
            size="sm"
            @click="$bvModal.hide('modal-centered')"
            >Save</b-button
          >
        </div>
        <div class="col-md-6">
          <b-button variant="outline-primary" class="cancel">Cancel</b-button>
        </div>
      </div>
    </b-modal> -->
    <!-- product mapping modal -->
    <b-modal
      header-class="product_mapping_modal"
      id="modal-centered"
      centered
      title="Add Product Mapping"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      hide-footer="true"
      v-model="triggerProductMappingModal"
      hide-header-close
    >
      <div class="pl-4 pb-2 pr-2">
        <p class="mb-0">
          <span class="link-class">{{ productMappingForm.sku }}</span>
        </p>
        <p>
          <span class="link-class">{{ channelTitle }}</span>
        </p>
        <div class="row" id="product-sku">
          <label class="col-md-3 pr-6 d-flex align-items-center" style="font-size: 12px; color:#606060"
            >Master SKU:</label
          >
          <w-text-input
            :fieldType="'text'"
            :className="' col-md-7 pt-1'"
            :disabled="false"
            :fieldName="'Master Sku'"
            :defaultValue="productMappingForm.masterSku"
            :labelStyle="'register-label'"
            @textInputChange="getMasterSku($event)"
          />
        </div>
        <div class="row" id="product-title">
          <label class="col-md-3 pr-6 d-flex align-items-center" style="font-size: 12px; color:#606060"
            >Product Title:</label
          >
          <w-text-input
            :fieldType="'text'"
            :className="'col-md-7 pt-1'"
            :disabled="false"
            :defaultValue="productMappingForm.productTitle"
            :labelStyle="'register-label'"
            @textInputChange="getProductTitle($event)"
          />
        </div>
        <div class="row d-flex justify-content-center mt-3 mb-2">
          <div class="mr-4">
            <b-button
              id="save-product"
              type="submit"
              class="save"
              size="sm"
              @click="showConfirmModal('Product')"
              :disabled="
                !productMappingForm.masterSku ||
                  !productMappingForm.productTitle"
              >Add Product</b-button>
          </div>
          <div class="">
            <b-button
              variant="outline-primary"
              class="cancel"
              id="cancel-product"
              @click="cancelProductMapping"
              >Cancel</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <!-- create alias modal -->
    <b-modal
      v-model="triggerAliasModal"
      header-class="create_alias_modal"
      id="modal-centerred"
      centered
      title="Create Alias"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :hide-footer="true"
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      hide-header-close
    >
      <div class="pl-4 pb-2 pr-2">
        <p class="mb-0">
          <span class="link-class">{{ productMappingForm.sku }}</span>
        </p>
        <p>
          <span class="link-class">{{ channelTitle }}</span>
        </p>
        <div class="row" id="aliass">
          <label class="col-md-6 pr-6 d-flex align-items-center" style="font-size: 14px; color:#1b1c1d">
            Existing Product to map to:
          </label>
          <w-text-input
            :fieldType="'text'"
            :className="'col-md-4 pt-1'"
            :disabled="false"
            :defaultValue="aliasForm.channelSku"
            :labelStyle="'register-label'"
            @textInputChange="getAliasMasterSku($event)"
          />
        </div>
        <div class="row d-flex justify-content-center mb-2 mt-3">
          <div class="mr-4">
            <b-button
              type="submit"
              class="save"
              id="save-alias"
              size="sm"
              :disabled="!aliasForm.channelSku"
              @click="showConfirmModal('Alias')"
              >Create Alias</b-button
            >
          </div>
          <div class="mr-4">
            <b-button
              variant="outline-primary"
              class="cancel"
              id="cancel-alias"
              @click="cancelCreateAlias"
              >Cancel</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmAliasOk"
    />

    <!-- loader -->
    <w-loading-spinner
      :loadSpinner="isloadingWidget"
      :isSchedulePickup="true"
    />
  </div>
</template>

<script>
import ReportsTable from "../../widgets/ReportsTable.vue";
import Table from "../../widgets/Table.vue";
import TextInput from "../../widgets/TextInput.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import Modal from "../../widgets/ModalWidget";
import moment from "moment";
import UploadListing from "./UploadListingTemplate.vue";
import LoadingSpiner from "../../widgets/LoadingSpinner"

import {
  getListingProduct,
  getListingProductStatus,
  getMappingStatus,
  addProductMapping,
  createAlias,
  downloadListingFile,
} from "../../Service/InventoryService";
import { getChannelList, isAuthorized } from "../../Service/CommonService";
export default {
  props:["isGetData"],
  components: {
    "w-table": ReportsTable,
    "listing-status-table": Table,
    "w-text-input": TextInput,
    TreeDropdown,
    Modal,
    "w-upload-listing": UploadListing,
    "w-loading-spinner": LoadingSpiner,
  },
  data() {
    return {
      is_filter_open:false,  // for filter open when we click on search btn
      companyID:sessionStorage.getItem("companyIds"),
      showTemplateBtns: false,
      isUploadTemplatePage: false,
      alertModalType: "",
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      isloadingWidget:false,
      triggerProductMappingModal: false,
      triggerAliasModal: false,
      channelList: [],
      selectedChannelList: [],
      channelIds: [],
      mappingList: [],
      productStatus: [
        { id: "ACTIVE", label: "ACTIVE" },
        { id: "INACTIVE", label: "INACTIVE" },
        { id: "INCOMPLETE", label: "INCOMPLETE" },
      ],
      selectedMappingList: [],
      selectedProductList: [],
      tabledata: {
        fields: [
          {
            key: "channelName",
            label: "Channel",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
          },
          {
            key: "totalListing",
            label: "Total Listing",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
          },
          {
            key: "unMapped",
            label: "Unmapped Listing",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
      listingStatusTableProp: {
        fields: [
          {
            key: "checkbox",
            label: "",
            selected: false,
          },
          {
            key: "channelName",
            label: "Channel",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
            selected: false,
          },
          {
            key: "sku",
            label: "SKU On Channel",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
            selected: false,
          },
          {
            key: "title",
            label: "Title",
            tdClass: "td-title-class",
            thClass: "thClass",
            class: "text-center",
            selected: false,
          },
          {
            key: "mappingStatus",
            label: "Mapping Status",
            tdClass: "td-status-class",
            class: "text-center",
            selected: false,
          },
          {
            key: "productStatus",
            label: "Product Status",
            tdClass: "td-status-class",
            class: "text-center",
            selected: false,
          },
          {
            key: "masterSku",
            label: "Master SKU",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
            selected: false,
          },
          {
            key: "alias",
            label: "Aliases",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
            selected: false,
          },
          {
            key: "actions_field",
            label: "Actions",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
            selected: false,
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
        allSelected: false,
      },
      productMappingForm: {
        masterSku: "",
        productTitle: "",
        channelId: "",
        sku: "",
      },
      aliasForm: {
        channelId: "",
        channelSku: "",
        sku: "",
      },
      channelTitle: "",
      // lastUpdated: "",
    };
  },
  created() {
    // this.getStatusDropdown();
    // // this.getListingProduct();
    // // this.loadData();
    // this.getChannelList();
    // this.getListingProductStatus();
    if(this.isGetData){
      this.getData();
    }
  },
  methods: {
    getData(){
      this.getStatusDropdown();
      this.getChannelList();
      this.getListingProductStatus();
    },

    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },

    // loadData() {
    //   this.getLastUpdated();
    //   this.getChannelList();
    //   this.getListingProductStatus();
    // },
    // To get  last updated time
    // getLastUpdated() {
    //   this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    // },
    getListingProductStatus() {
      getListingProductStatus(this.companyID)
        .then((res) => {
          if (!res.errorCode) {
            this.tabledata.items = [];
            this.tabledata.items = res.response;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.alertModalType = "";
          }
        })
        .catch((e) => {
          this.alert = true;
          this.alertMsg = e.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
          this.alertModalType = "";
        });
    },
    getSku(event) {
      this.sku = event;
      this.getListingProduct();
    },
    selectedChannel(event) {
      this.selectedChannelList = event;
      this.getListingProduct();
    },
    selectedMappingStatus(event) {
      this.selectedMappingList = event;
      this.getListingProduct();
    },
    selectedProductStatus(event) {
      this.selectedProductList = event;
      this.getListingProduct();
    },
    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          if (res) {
            this.channelList = [];
            res.forEach((element) => {
              this.channelList.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((e) => {
          this.alert = true;
          this.alertMsg = e.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
          this.alertModalType = "";
        });
    },
    getStatusDropdown() {
      this.mappingList = [];
      getMappingStatus()
        .then((res) => {
          res.map((element) => {
            this.mappingList.push({
              id: element,
              label: element,
            });
          });
        })
        .catch((e) => {
          this.alert = true;
          this.alertMsg = e.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
          this.alertModalType = "";
        });
    },
    getListingProduct() {
      // this.showTemplateBtns = true;
      if(this.selectedMappingList.includes("UNMAPPED")){
        this.showTemplateBtns=true;
      }else{
        this.showTemplateBtns=false;
      }
      
      this.listingStatusTableProp.allSelected = false;
      getListingProduct(
        this.selectedChannelList,
        this.sku,
        this.selectedMappingList,
        this.listingStatusTableProp.currentPage - 1,
        this.listingStatusTableProp.perPage,
        this.selectedProductList,
        this.companyID,
      )
        .then((res) => {
          if (!res.errorCode) {
            this.listingStatusTableProp.items = [];
            let resp = res.response;
            this.listingStatusTableProp.items = resp.content;

            this.listingStatusTableProp.items.forEach((item) => {
              item.selected = false;
            });

            this.listingStatusTableProp.totalRows = resp.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.alertModalType = "";
          }
        })
        .catch((error) => {
          this.alert = true;
          this.alertMsg = error.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
          this.alertModalType = "";
        });
    },

    openBundle() {
      this.$bvModal.show("modal-centerr");
    },
    openAliasModal(product) {
      this.channelTitle = product.title;
      this.productMappingForm.productTitle = product.title;
      this.productMappingForm.channelSku = product.masterSku;
      this.aliasForm.channelId = product.channelId;
      this.aliasForm.sku = product.sku;
      this.triggerAliasModal = true;
    },
    openAddModal(product) {
      this.channelTitle = product.title;
      this.productMappingForm.productTitle = product.title;
      this.productMappingForm.masterSku = product.sku;
      this.productMappingForm.sku = product.sku;
      this.productMappingForm.channelId = product.channelId;
      this.triggerProductMappingModal = true;
    },
    getMasterSku(event) {
      this.productMappingForm.masterSku = event;
    },
    getProductTitle(event) {
      this.productMappingForm.productTitle = event;
    },
    getAliasMasterSku(event) {
      this.aliasForm.channelSku = event;
    },
    getSelectedPage(currentPage) {
      this.listingStatusTableProp.currentPage = currentPage;
      this.getListingProduct();
    },
    getSelectedPerPage(perPage) {
      this.listingStatusTableProp.perPage = perPage;
      this.listingStatusTableProp.currentPage = 1;
      this.getListingProduct();
    },
    addProductMapping() {
      addProductMapping(this.productMappingForm, this.companyID)
        .then((res) => {
          if (res) {
            this.alert = true;
            this.alertMsg = "Product mapped successfully.";
            this.alertType = "";
            this.isConfirmAlert = false;
            this.triggerProductMappingModal = false;
            this.getListingProduct();
          }
        })
        .catch((e) => {
          this.alert = true;
          this.alertMsg = e.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
          this.alertModalType = "";
        });
    },
    createAlias() {
      createAlias(this.aliasForm, this.companyID)
        .then((res) => {
          if (res) {
            this.alert = true;
            this.alertMsg = "Alias created successfully.";
            this.alertType = "";
            this.isConfirmAlert = false;
            this.triggerAliasModal = false;
            this.getListingProduct();
          }
        })
        .catch((e) => {
          this.alert = true;
          this.alertMsg = e.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
          this.alertModalType = "";
        });
    },
    cancelProductMapping() {
      this.productMappingForm.masterSku = "";
      this.productMappingForm.productTitle = "";
      this.productMappingForm.sku = "";
      this.productMappingForm.channelId = "";
      this.triggerProductMappingModal = false;
    },
    cancelCreateAlias() {
      this.aliasForm.channelId = "";
      this.aliasForm.channelSku = "";
      this.aliasForm.sku = "";
      this.triggerAliasModal = false;
    },
    showConfirmModal(type) {
      this.alertModalType = type;
      this.alert = true;
      this.alertMsg =
        type == "Alias"
          ? "Are you sure you want to create alias ?"
          : "Are you sure you want to add this product ?";
      this.alertType = "";
      this.isConfirmAlert = true;
    },
    confirmAliasOk() {
      if (this.alertModalType == "Alias") {
        this.createAlias();
      } else {
        this.addProductMapping();
      }
    },
    // for upload template
    goToUploadpage(event) {
      this.isUploadTemplatePage = true;
    },
    goBackToPreviousPage(event) {
      this.isUploadTemplatePage = event;
    },

    // for download template of listing status
    downloadTemplate() {
      this.isloadingWidget = true;
      let downloadData = {
        companyId: this.companyID,
        bulkMappingDtoList: [],
      };
      this.listingStatusTableProp.items.forEach((item) => {
        if (item.selected) {
          downloadData.bulkMappingDtoList.push({
            sku: item.sku,
            channelName: item.channelName,
          });
        }
      });

      if (downloadData.bulkMappingDtoList.length > 0) {
        downloadListingFile(downloadData)
          .then((res) => {
            this.isloadingWidget = false;
            // this.alert = true;
            // this.alertMsg = res.status === "FAILURE" ? res.failureMessage : res.response;
            // this.alertType = "";
            // this.isConfirmAlert = false;
            this.listingStatusTableProp.perPage = 50;
            this.listingStatusTableProp.currentPage = 1;
            this.listingStatusTableProp.allSelected = false;
            this.getListingProduct();
          })
          .catch((err) => {
            this.isloadingWidget = false;
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.getListingProduct();
          });
      } else {
        this.isloadingWidget = false;
        this.alert = true;
        this.alertMsg = "Please select atleast one product to map.";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },

    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
      this.alertModalType = "";
    },

    // open filters
    openFilter(){
      this.is_filter_open = !this.is_filter_open;
    },

  },
};
</script>
<style>
/* for create alias modal and product mapping modal */
.create_alias_modal,
.product_mapping_modal,
.mark_bundle_modal {
  border-bottom: 1px solid #d9d9d9 !important;
  padding: 10px;
}
.create_alias_modal .modal-title,
.product_mapping_modal .modal-title,
.mark_bundle_modal .modal-title {
  font-family: "roboto-bold";
  font-size: 16px;
  font-weight: 600;
  color: #4A4A4A;
}
/* for create alias modal width */
div.modal .modal-dialog.modal-400 {
  width: 400px !important;
}

@media only screen and (min-width: 320px) and (max-width: 767.98px){
  .listing-table .show-page{
    margin: 5px !important;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 555px){
  .listing-inventory .modal-content{
    margin-top: 40px;
  }
}
@media only screen and (max-width: 400px){
  .listing-pagination .page-link{
    min-width: 10px !important;
  }
}
</style>
<style scoped>
.select_label_style {
  font-size: 14px;
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;
  font-weight: 600;
}
/* .card-body {
  padding-top: 0px;
} */
.search {
  width: 96px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px #2185D0;
  background-color: #2185D0;
  line-height: normal;
  color: #fff;
  font-size: 14px;
  /* margin-left: 30px; */
}
.show-class {
  color: #1b1c1d;
  font-size: 14px;
  font-weight: 600;
  font-family: "proxima-nova", sans-serif !important;
}
.btnn {
  background-color: #ffffff;
  border: solid 1px #2e8cd3;
  color: #2e8cd3;
  /* margin: 0px; */
  height: 27px;
  padding: 0 25px 0 12px;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif !important;
  border-radius: 2px;
}
img:hover {
  color: #5fa6e7;
}
.save {
  border-radius: 5px;
  border: solid 1px #2185D0;
  background-color: #2185D0;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  padding: 3px 25px;
  height: 35px;
}
.cancel {
  border-radius: 5px;
  border: solid 1px #2185D0;
  background-color: #ffffff;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #2185D0;
  padding: 3px 25px;
  height: 35px;
}
.prod_map_save {
  padding: 3px 7px;
}
.mark_bundle_save {
  padding: 3px 65px;
}
input[type="text"] {
  border: 2px solid black;
}
.cards {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d9d9d9;
}
input[type="text"],
select {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.link-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  font-weight: 500;
  color: #2E8CD3;
}

.inventory-filter ::v-deep .form-control {
  height: 38px !important;
}
.inventory-filter ::v-deep .lable-name{
  font-size: 12px;
}
.listing-status-table ::v-deep .card-body{
  padding-top: 20px;
}
.listing-table ::v-deep .inventory-pagiantion{
  margin-bottom: 10px;
}
.listing-table ::v-deep table tr {
  white-space: nowrap;
}
.listing-table ::v-deep table thead tr th {
  text-align: center !important;
}
.listing-table ::v-deep table tbody tr{
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
.listing-table ::v-deep table tr .td-title-class{
  max-width: 100% !important;
}
.listing-table ::v-deep table tbody tr td {
  text-align: center !important;
}
.listing-table ::v-deep table thead tr th:first-child {
  text-align: left !important;
}
.listing-table ::v-deep table tbody tr td:first-child {
  text-align: left !important;
}
.listing-table ::v-deep table thead tr th:first-child{
  z-index: 1;
}
.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 2;
  top:0;
}
</style>
