<template>
  <div id="inventory-table">
    <div class="row table-div mx-2 mb-1 pb-2">
      <div class="col-md-1 col-4 img-div">
      <!-- <div>
        <b-form-checkbox
          id="checkbox-p"
          v-model="status"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        ></b-form-checkbox>
      </div> -->
        <div class="d-flex justify-content-center align-items-center h-100">
          <img :src="productTableData.imageUrl" style="width: -webkit-fill-available" />
        </div>
      </div>
      <div class="col-md-11 col-8 sub-div">
        <div class="row border-bottom d-flex justify-content-between">
          <div class="py-2">
            <!-- <div> -->
              <span
                class="product-Id"
                v-if="productTableData.masterSku"
                @click="goToProduct(productTableData)"
              >
                {{ productTableData.masterSku }}&nbsp;
                <span class="slash">&nbsp;/&nbsp;</span>
              </span>
              <span
                v-if="productTableData.mappingStatus == 'MAPPED'"
                class="product-name"
                style="font-weight:bold !important"
                @click="goToProduct(productTableData)"
              >&nbsp;{{ productTableData.title }}</span>
              <span
                v-else
                class="product-title"
              >&nbsp;
                <span v-if="productTableData.title.length < 120">{{ productTableData.title }}</span>
                <span v-else v-b-popover.hover.html.top="productTitlehover(productTableData.title)">
                  {{ productTableData.title.substring(0, 120) }}...
                </span> 
              </span>
            <!-- </div> -->
          </div>
          <div class="mr-5 py-2">
            <span class="mr-5 unit-price">
              {{productTableData.currencySymbol}} {{productTableData.unitPrice}}
            </span>
            <span style="cursor:pointer" v-b-popover.hover.html.bottomleft="dimensionDetails(productTableData)">
              <img src="../assets/SVG/dimensions.svg">
            </span>
          </div>
        </div>
        <div class="row">
          <div class="product-details pt-2">
            <div class="pl-0 product-feature">
              <p class="sub-title">SKU</p>
              <p class="sub-value" style="word-break:break-all">{{ productTableData.sku }}</p>
            </div>
            <div class="pl-0 product-feature">
              <p class="sub-title">Category</p>
              <p class="sub-value" style="word-break:break-all">{{ productTableData.category }}</p>
            </div>
            <div class="product-feature">
              <p class="sub-title">Brand</p>
              <p class="sub-value" style="word-break:break-all">{{ productTableData.brand }}</p>
            </div>
            <div class="product-feature">
              <p class="sub-title">Alias</p>
              <p class="sub-value" v-for="alias in productTableData.alias" :key="alias" style="word-break:break-all">{{ alias }}</p>
            </div>
            <div class="product-feature">
              <p class="sub-title">Sellable</p>
              <p class="sub-value">{{ productTableData.sellerSellableQuantity }}</p>
              <p
                class="my-0 seller-market-link"
                @click="goToSellerDetail(productTableData)"
              >Seller: {{ productTableData.sellerSellableQuantity }}</p>
              <p
                class="my-0 seller-market-link"
                @click="goToMarketPlace(productTableData)"
              >Marketplace: {{ productTableData.marketplaceSellableQuantity }}</p>
            </div>
            <div class="product-feature">
              <p class="sub-title">Bad</p>
              <p class="sub-value">{{ productTableData.badQuantity }}</p>
            </div>
            <div class="product-feature">
              <p class="sub-title">Marketplace</p>
              <div class="d-flex pl-0">
                <img class="ml-4" :src="productTableData.marketplaceImageUrl" style="height:15px" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 sellable-links-mobile">
        <div class="row d-flex w-100 mx-auto mt-4 mb-2 text-center justify-content-around">
          <div class="col-5 sellable-btns" @click="goToSellerDetail(productTableData)">Seller ({{productTableData.sellerSellableQuantity}})</div>
          <div class="col-5 sellable-btns" @click="goToMarketPlace(productTableData)">Marketplace ({{productTableData.marketplaceSellableQuantity}})</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props: ["productTableData"],
  data() {
    return { };
  },
  methods: {
    goToProduct(event) {
      this.$emit("goToProduct", event);
    },
    goToSellerDetail(event) {
      this.$emit("goToSellerDetail", event);
    },
    goToMarketPlace(event){
      this.$emit("goToMarketPlace", event)
    },
    
    // product title hover
    productTitlehover(title){
      return (
              "<span style='color:#4A4A4A; font-size:12px; font-family: roboto-regular' >" + `${title}` + "</span>"
      )
    },

    // product dimensions image popup hover 
    dimensionDetails(product){
        return (
          "<div style='display:flex'>"

            +"<div style='margin:0 6px'>" 
              +"<span style='color:#7B7B7B; font-size:12px;'>"+ `Weight` +"</span>" 
              +"<br>"
              +"<span style='color:#4A4A4A; font-size:12px; font-family: roboto-bold; white-space:nowrap'>"+ `${product.weight}` +"</span>" +
            "</div>"

            +"<div style='margin:0 6px'>" 
              +"<span style='color:#7B7B7B; font-size:12px;'>"+ `Height` +"</span>" 
              +"<br>"
              +"<span style='color:#4A4A4A; font-size:12px; font-family: roboto-bold; white-space:nowrap'>"+ `${product.height}` +"</span>" +
            "</div>"

            +"<div style='margin:0 6px'>" 
              +"<span style='color:#7B7B7B; font-size:12px;'>"+ `Width` +"</span>" 
              +"<br>"
              +"<span style='color:#4A4A4A; font-size:12px; font-family: roboto-bold; white-space:nowrap'>"+ `${product.width}` +"</span>" +
            "</div>"

            +"<div style='margin:0 6px'>" 
              +"<span style='color:#7B7B7B; font-size:12px;'>"+ `Length` +"</span>" 
              +"<br>"
              +"<span style='color:#4A4A4A; font-size:12px; font-family: roboto-bold; white-space:nowrap'>"+ `${product.length}` +"</span>" +
            "</div>"

          +"</div>"
        );
    },

    // open(event) {
    //   console.log(event.target.innerText);
    //   this.seller = event.target.innerText === "Seller" ? true : false;
    //   this.markett = event.target.innerText === "Marketplace" ? true : false;
    // }
  }
};
</script>

<style scoped>
.table-div {
  /* display: flex; */
  background-color: #ffffff;
  border-radius: 5px;
}
.img-div {
  width: 155px;
  padding: 5px;
}
.product-Id {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: #2e8cd3;
  cursor: pointer;
}
.slash {
  color: #e0e0e0;
}
.product-name {
  cursor: pointer;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #2e8cd3;
}
/* .container-1 {
  display: flex;
} */
/* .sub-div {
  width: 100%;
} */
.seller-market-link{
  font-family: "roboto-regular";
  color: #2E8CD3;
  font-size: 10px;
  cursor: pointer;
}
.sellable-links-mobile{
  display: none;
}

.product-title{
  font-family: "roboto-bold";
  font-size:16px;
  color: #4A4A4A;
  /* margin-left: 15px; */
}

.sub-title {
  font-family: "roboto-regular";
  font-size: 12px;
  color: #7B7B7B;
  margin-bottom: 0px;
}
.sub-value {
  font-family: "roboto-bold";
  font-size: 14px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 0px;
   width:120px
}
.sub-value-small {
  cursor: pointer;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e8cd3;
  margin-bottom: 0px;
}
img {
  margin-right: 0px;
}
.product-details{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* overflow-x: scroll;
  display: -webkit-inline-box; */
  margin-right: 10px;
  /* padding-left: 15px; */
  /* padding-right: 50px; */
  /* align-items: center; */
  /* padding-top: 5px; */
}
#inventory-table{
  padding-top: 25px;
  padding-bottom: 6px;
}
#inventory-table .table-div {
  border: 1px solid #e0e0e0;
}
.sellable-btns{
  color:#fff;
  border:1px solid #2185D0; 
  font-size:10px; 
  padding:7px;
  border-radius:5px;
  background:#2185D0;
  cursor: pointer;
}
.unit-price{
  font-family: "roboto-bold";
  color: #4A4A4A;
  font-size: 16px;
}
 .b-popover {
  box-shadow: 0px 1px 3px #00000029;
  max-width: 285px;
}

/* for responsive */
@media screen and (max-width: 1050px) {
  .product-details{
    overflow-x: scroll;
    display: -webkit-inline-box;
  }
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
}
@media screen and (max-width: 768px){
  /* .product-details{
    overflow-x: scroll;
    display: -webkit-inline-box;
  } */
  .product-feature{
    margin: 0 10px;
  }
  .seller-market-link{
    display: none;
  }
  .sellable-links-mobile{
    display: block;
  }
  .product-title{
    font-size: 14px;
  }
  .sub-value{
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .img-div img{
    width: 50px;
  }
}
</style>
