<template>
  <div class="seller_table">
    <div class="d-flex justify-content-between my-3 mx-2 pt-1 heading-back">
      <p class="mb-0 seller_heading">Marketplace Warehouse and Channel Stock Distribution</p>
      <span type="submit" @click="goTooStock()">
        <img src="../../assets/SVG/Back.svg" width="60px">
      </span>
    </div>
    <b-card class="m-2">
      <w-table
        :tableData="tabledata"
        @resendRowDataEvent="openResend($event)"
        @deleteRowDataEvent="openConfirmationModal($event)"
      ></w-table>
    </b-card>

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

  </div>
</template>

<script>
import ReportsTable from "../../widgets/ReportsTable.vue";
import Modal from "../../widgets/ModalWidget";
import { getMarketplacerDetail } from "../../Service/InventoryService";

export default {
  components: {
     "w-table": ReportsTable,
     Modal
  },
  props:["productId"],
  data() {
    return {
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      tabledata: {
        fields: [
          {
            key: "warehouseName",
            label: "Warehouse Name"
          },
          {
            key: "sellableQuantity",
            label: "Sellable Stock"
          },
          {
            key: "badQuantity",
            label: "Bad Stock"
          },
          {
            key: "reservedQuantity",
            label: "Reserved Stock"
          },
          {
            key: "totalstock",
            label: "Total Stock"
          },
          {
            key: "stockChannel",
            label: "Marketplace"
          }
        ],

        items: [
          // {
          //   warehouse: "Amazon BLR",
          //   sellable: 95,
          //   reserved: '-',
          //   amazon: 20,
          //   flipkart:11
          // },
          //  {
          //   warehouse: "Flipkart_MP_FC",
          //   sellable: 15,
          //   reserved: '-',
          //   amazon: 50,
          //   flipkart: 66
          // }
        ]
      }
    };
  },
  created(){
    this.getMarketplacerDetail();
  },

  methods:{
    goTooStock(){
      this.$emit("backfrommarketplace", false);
    },

    getMarketplacerDetail(){
      getMarketplacerDetail(this.productId, this.companyID).then((res) => {
        const resp = res.response;
        if(resp != ""){
          this.tabledata.items = resp.map((el) => {
            return {
              ...el,
              totalstock: el.sellableQuantity + el.reservedQuantity
            }
          })
        }else{
          this.alert = true;
          this.alertMsg = res.failureMessage;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        }
        
      }).catch(err => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    }
  }
};
</script>
<style>
 .seller_table .table thead th {
  font-family: "proxima-nova", sans-serif;
  color: #1b1c1d;
  font-size: 13px;
  padding-left: 10px;
  text-align: center;
}
.seller_table .table tbody tr{
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
.seller_table .table tbody td {
  font-family: "proxima-nova", sans-serif;
  font-size: 13px;
  color: #1b1c1d;
  padding: 15px 0 15px 10px;
  text-align: center;
}

.seller_table .table tbody tr:nth-child(1) td:last-child {
  font-weight: bold;
}
.seller_table .table tbody td img {
  width: 20px;
}

.seller_heading { 
  font-family: "roboto-bold";
  font-size: 16px;
  color: #4A4A4A;
}

@media screen and (max-width: 575px){
  .heading-back{
    flex-direction: column-reverse !important;
    padding-top: 0 !important;
  }
  .seller_heading{
    font-size: 12px !important;
    margin-top: 10px;
  }
  .heading-back span{
    text-align: right !important;
  }
}
</style>
