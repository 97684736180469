<template>
  <div class="upload-bulk">
    <div
      class="row mx-2 mb-3 px-2 d-flex justify-content-between align-items-center"
    >
      <div class="upload-heading">Adjust Stock in Bulk</div>
      <div role="button" @click="goBackToPreviousPage">
        <img src="../../assets/CloseModel.svg" width="20px" height="20px" />
      </div>
    </div>
    <p class="stock-label mb-0">Upload Template</p>
    <div class="m-2 d-flex file-btn-group">
      <div class="col-lg-6 col-md-7 col-12 pt-0">
        <b-form-file
          style="display:none"
          id="upload-custom-file"
          accept=".xlsx"
          v-model="selectedFile"
        ></b-form-file>
        <label for="upload-custom-file" class="file-drop-box">
          <img src="../../assets/SVG/pdf.svg" width="30px" />
          <span style="color:#929292">Drop your files here or click to upload</span>
          <span class="pt-1" style="color:#adaaaa">Supports: .XLS</span>
        </label>
      </div>
      <div class="d-flex align-items-end packing-buttons pb-4 mx-3">
        <w-button-input
          :isDisabled="isUploadBtnDisabled"
          :buttonClass="'auth-button'"
          :buttonStyle="'auth-button-style'"
          @buttonClicked="uploadBulkStock()"
          :label="'Upload'"
        />
        <w-button-input
          class="ml-3"
          :buttonClass="'request-demo'"
          :buttonStyle="'auth-button-style'"
          @buttonClicked="cancelPackingSlip()"
          :label="'Cancel'"
        />
      </div>
    </div>

    <!-- error modal  -->
    <w-loading-spinner
      :loadSpinner="isloadingWidget"
      :isSchedulePickup="true"
    />
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import Modal from "../../widgets/ModalWidget";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import ButtonInput from "../../widgets/InputButton";
import { uploadBulkStock } from "../../Service/InventoryService";

export default {
  components: {
    "w-button-input": ButtonInput,
    Modal,
    "w-loading-spinner": LoadingSpinner,
  },
  data() {
    return {
      isloadingWidget: false,
      selectedFile: null,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      errorMsg: "",
    };
  },
  computed: {
    isSubmitBtnDisabled() {
      if (!this.selectedFile == "") {
        return false;
      }
      return true;
    },
  },

  methods: {
    uploadBulkStock() {
      this.isloadingWidget = true;
      let formData = new FormData();
      formData.append("file", this.selectedFile);
        console.log("form data", formData)
      uploadBulkStock(formData)
        .then((res) => {
            console.log("upload bulk stock ", res)
          this.isloadingWidget = false;
          this.alert = true;
          this.alertMsg = res.response || "Successfully Uploaded";
          this.alertType = "Success";
          this.isConfirmAlert = false;

          this.selectedFile = "";
        })
        .catch((err) => {
          this.isloadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message || "Oops something went wrong?";
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    cancelPackingSlip() {
      this.selectedFile = [];
    },
    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // for navigation
    goBackToPreviousPage() {
      this.$emit("goBackToPreviousPage");
    },
  },
};
</script>

<style scoped>
.file-drop-box {
  cursor: pointer;
}
.stock-label {
    font-size: 12px;
    opacity: 0.8;
    margin-left: 20px;
}
.upload-bulk {
  background: #fff;
  /* box-shadow: 0px 1px 3px #00000029; */
  border-radius: 5px;
}
.upload-heading {
  color: #1b1c1d;
  font-size: 18px;
  font-weight: 600;
}
.upload-btn {
  background-color: white;
  border: 1px solid #2e8cd3;
  padding: 7px 40px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: left;
  color: #2e8cd3;
  height: 32px;
  border-radius: 2px;
}
.upload-bulk .file-drop-box {
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  box-shadow: 0 1px 3px rgb(0 0 0 / 16%);
  background: #f5f5f5 0 0 no-repeat padding-box;
}
.upload-bulk .file-drop-box span {
  text-align: center;
  padding: 20px 10px 0 10px;
  color: #aeaeae;
  font-size: 12px;
}
.upload-bulk ::v-deep .request-demo {
  background: #fff;
  border: 1px solid #005d84;
  padding: 0.5rem !important;
}
.packing-buttons{
    width: 100%;
}

@media screen and (max-width: 991px) {
  .file-btn-group {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .upload-bulk {
    margin: 40px 20px 0 20px;
  }
}

@media screen and (max-width: 430px) {
  .packing-buttons ::v-deep button {
    width: 7rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 760px){
  .packing-buttons ::v-deep .auth-button{
      height: 42px;
  }
}
</style>
