<template>
  <div class="stock_inventory">
    <w-seller
      v-if="Seller == true"
      @backfromseller="backfromseller($event)"
      :productId="productId"
    />
    <w-marketplace
      v-if="Marketplace == true"
      @backfrommarketplace="backfrommarketplace($event)"
      :productId="productId"
    />
    <w-individual-products
      v-if="showIndividualProduct == true"
      :masterSku="masterSku"
      @backfromproduct="backfromproduct($event)"
    />
    <div v-if="!Seller && !Marketplace && !showIndividualProduct">
      <div v-if="lowStock">
        <w-stock @goback="backk($event)"></w-stock>
      </div>
      <div v-if="stocks">
        <w-limitStock @gobackk="backed($event)"></w-limitStock>
      </div>
      <div v-if="!lowStock && !stocks" style="margin-bottom: 50px;" class="mt-2">
        <!-- <span class="ml-2 summary_refresh_text"
            >Last Updated On: {{ lastUpdated ? lastUpdated : "-" }}</span
          >&nbsp;&nbsp;<span class="summary_refresh">
            <img
              style="cursor:pointer"
              @click="loadData()"
              src="../../assets/refresh.png"
              width="15px"
            />
          </span> -->
        <b-card class="m-2 stock-filter" :body-class="'card-padding'" v-if="isDesktopFilterOpen">
          <img class="mt-3 close-filter-icon" src="../../assets/CloseModel.svg" width="15px" @click="openDesktop_filter">
          <div class="row align-items-end">
            <div class="col-md-3">
              <!-- <label class="tree-label">SKU/ Alias</label> -->
              <w-text-input
                :fieldType="'text'"
                :disabled="false"
                :defaultValue="skuAliasValue"
                :labelStyle="'register-label'"
                :validation="{ alpha_num: true }"
                :fieldName="'SKU/Alias'"
                @textInputChange="getSkuAliasValue($event)"
                :labelName="'SKU/ Alias'"
              />
            </div>
            <div class="col-md-3">
              <!-- <label class="tree-label">Title</label> -->
              <w-text-input
                :fieldType="'text'"
                :disabled="false"
                :defaultValue="titleValue"
                :labelStyle="'register-label'"
                :validation="{ alpha_num: true }"
                :fieldName="'Title'"
                @textInputChange="getTitleValue($event)"
                :labelName="'Title'"
              />
            </div>
            <div class="col-md-3">
              <w-tree-dropdown
                :labelText="'Brands'"
                :rootOptions="brandsListOptions"
                :selectedValue="brandsListValues"
                @selectedField="selectedBrandsCategory($event)"
              />
            </div>
            <div class="col-md-3">
              <w-tree-dropdown
                :labelText="'Channels'"
                :rootOptions="channelListOptions"
                :selectedValue="channelListValues"
                @selectedField="selectedChannelsCategory($event)"
              />
            </div>
            
          </div>
          
        </b-card>
        <div class="row d-flex justify-content-between filter-with-pagination m-2 p-0 pt-4">
          <div class="d-flex align-items-center" id="stock-filter" style="cursor:pointer" @click="openDesktop_filter">
            <img src="../../assets/SVG/desktopFilter.svg"> 
            <span style="color:#2E8CD3; font-size:14px">Filters</span>
          </div>
          <div class="p-0">
            <div class="row p-0 pt-1 pagination-section">
              <!-- <b-dropdown variant="outline-primary" class="dropp ml-auto">
                
                <b-dropdown-header
                  id="dropdown-header-2"
                  @click="reset($event)"
                  style="font-family:ProximaNova"
                  >BULK ACTION</b-dropdown-header
                >
                <b-dropdown-item @click="reset($event)"
                  >Bulk Low Stock Limit Reset</b-dropdown-item
                >
              </b-dropdown>-->
              <div class="pr-1 d-flex align-items-center">
                <span class="bulk-stock" @click="openBulkStockModel">Adjust Stock In Bulk</span>
                <span class="bulk-divider"></span>
                <span class="show-class mr-2 ml-auto" v-if="totalRows >= 5"
                  >Show</span
                >
                <b-form-select
                  v-if="totalRows >= 5"
                  style="width: 65px; height: 33px; box-shadow: none;font-size:12px"
                  v-model="perPage"
                  :options="getPerPageOptions(totalRows)"
                  @change="getSelectedPerPage($event)"
                ></b-form-select>
              </div>
              <div class="pagination-class align-items-center">
                <span class="show-class" v-if="totalRows >= 5">entries</span>
                <span class="bulk-divider"></span>
                <b-pagination
                  v-if="totalRows >= 5"
                  :class="totalRows >= 5 ? 'pr-3' : 'pr-3 ml-auto'"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  v-model="currentPage"
                  :limit="1"
                  aria-controls="my-table"
                  @change="getSelectedPage($event)"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <w-product-table
          v-for="item in tableProperties.items"
          :key="item"
          :productTableData="item"
          @goToProduct="individualProduct($event)"
          @goToSellerDetail="sellerDetailPage($event)"
          @goToMarketPlace="marketPlacePage($event)"
        />
      </div>
    </div>
    <!-- upload bulk stock -->
    <b-modal
      id="upload-bulk-stock"
      size="xl"
      hide-footer="true"
      hide-header-close
      centered
    >
      <w-upload-bulk-modal 
        @goBackToPreviousPage="goBackToPreviousPage"
      ></w-upload-bulk-modal>
    </b-modal>
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import Seller from "./Seller.vue";
import Marketplace from "./Marketplace.vue";
import IndividualProducts from "./IndividualProducts.vue";
import StockReset from "./StockReset.vue";
import Modal from "../../widgets/ModalWidget";
import LimitStockReset from "./LimitStockReset.vue";
import TextInput from "../../widgets/TextInput.vue";
import TreeDropdown from "../../widgets/TreeDropdown.vue";
import InventoryProductTable from "../../widgets/InventoryProductTable.vue";
import UploadBulkStock from "./UploadBulkStock"
import moment from "moment";
import {
  getBrandsTypes,
  getChannnelTypes,
  getStockFilterTable,
} from "../../Service/InventoryService";
export default {
  props:["isGetData"],
  components: {
    Modal,
    "w-seller": Seller,
    "w-individual-products": IndividualProducts,
    "w-marketplace": Marketplace,
    "w-stock": StockReset,
    "w-limitStock": LimitStockReset,
    "w-text-input": TextInput,
    "w-tree-dropdown": TreeDropdown,
    "w-product-table": InventoryProductTable,
    "w-upload-bulk-modal": UploadBulkStock,
  },
  data() {
    return {
      isDesktopFilterOpen:false, // for desktop filter
      companyID:sessionStorage.getItem("companyIds"),
      masterSku: null,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      skuAliasValue: "",
      titleValue: "",
      brandsListOptions: [],
      brandsListValues: [],
      channelListOptions:[],
      channelListValues:[],
      Seller: false,
      productId: "",
      Marketplace: false,
      showIndividualProduct: false,
      lowStock: "",
      stocks: "",
      a: "",
      b: "",
      c: "",
      tableProperties: {
        items: [],
      },
      options: [
        { value: "2", text: "2" },
        { value: "5", text: "5" },
        { value: "10", text: "10" },
        { value: "15", text: "15" },
        { value: "20", text: "20" },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      generateList: [
        {
          text: "Archive/Unarchive",
          value: "Archive/Unarchive",
          variant: "primary",
        },
      ],
      // lastUpdated:''
    };
  },
  created() {
    // this.getBrandsTypes();
    // this.getChannnelTypes();
    // // this.loadData();
    //  this.getStockFilterTable();
    if(this.isGetData){
      this.getData();
    }
  },
  methods: {
    getData(){
      this.getBrandsTypes();
      this.getChannnelTypes();
      this.getStockFilterTable();
    },

    // loadData() {
    //   this.getLastUpdated();
    //   this.getStockFilterTable();
    // },
    // To get  last updated time
    // getLastUpdated() {
    //   this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    // },
    getPerPageOptions() {
      let options = [];
      options.push({ text: "5", value: 5 });
      for (let index = 5; index < 100; ) {
        index = index + 5;
        options.push({ text: index, value: index });
      }
      return options;
    },
    getSelectedPerPage(event) {
      this.perPage = event;
      this.currentPage = 1;
      this.getStockFilterTable();
    },
    getSelectedPage(event) {
      this.currentPage = event;
      this.getStockFilterTable();
    },
    getSkuAliasValue(event) {
      this.skuAliasValue = event;
      this.currentPage = 1;
      this.getStockFilterTable();
    },
    getTitleValue(event) {
      this.titleValue = event;
      this.currentPage = 1;
      this.getStockFilterTable();
    },
    getBrandsTypes() {
      getBrandsTypes(this.companyID)
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            resp.forEach((element) => {
              this.brandsListOptions.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

// for channel dropdown
    getChannnelTypes() {
      getChannnelTypes(this.companyID)
        .then((res) => {
          if (!res.errorCode) {
            res.forEach((element) => {
              this.channelListOptions.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    selectedBrandsCategory(event) {
      this.brandsListValues = event;
      this.getStockFilterTable();
    },

     selectedChannelsCategory(event) {
      this.channelListValues = event;
      this.getStockFilterTable();
    },
    
    getStockFilterTable() {
      this.tableProperties.items = [];
      getStockFilterTable(
        this.brandsListValues,
        this.channelListValues,
        this.skuAliasValue,
        this.titleValue,
        this.currentPage - 1,
        this.perPage,
        this.companyID,
      )
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            this.tableProperties.items = resp.content;
            this.totalRows = resp.totalElements;
          } else {
            this.alert = false;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    reset(event) {
      this.lowStock =
        event.target.innerText === "Bulk Stock Reset" ? true : false;
      this.stocks =
        event.target.innerText === "Bulk Low Stock Limit Reset" ? true : false;
    },
    backk(event) {
      this.stocks = false;
      this.lowStock = false;
    },
    backed(event) {
      this.stocks = false;
      this.lowStock = false;
    },
    individualProduct(event) {
      this.showIndividualProduct = true;
      this.masterSku = event.masterSku;
    },
    sellerDetailPage(event) {
      this.productId = event.productId;
      this.Seller = true;
    },
    marketPlacePage(event) {
      this.productId = event.productId;
      this.Marketplace = true;
    },
    backfromseller(event) {
      this.Seller = event;
    },
    backfrommarketplace(event) {
      this.Marketplace = event;
    },
    backfromproduct(event) {
      this.showIndividualProduct = event;
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // open a desktop filer
    openDesktop_filter(){
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter(){
      this.isDesktopFilterOpen=false;
    },
    // open upload template modal
    openBulkStockModel() {
      this.$bvModal.show("upload-bulk-stock");
    },
    goBackToPreviousPage() {
      this.$bvModal.hide("upload-bulk-stock");
    },
  },
};
</script>
<style>
.stock_inventory{
  margin-bottom: 90px
}
.stock_inventory input[type="checkbox"] + label:before {
  width: 17px;
  height: 17px;
}
</style>
<style scoped>
.bulk-stock{
  font-size: 12px;
  color: #2e8cd3;
  cursor: pointer;
}
.bulk-divider{
  /* padding: 0 15px;
  opacity: 0.7; */
  border-right: 1px solid #BBBBBB;
  margin: 0 10px;
  display: inline-block;
  height: 20px;
}
.filter_applied_text {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  color: #1b1c1d;
}
.reset_text {
  font-size: 16px;
  font-family: "proxima-nova", sans-serif !important;
  color: #2e8cd3;
  margin-right: 90px;
}
.stock_dropdown_label {
  font-size: 14px;
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;
  font-weight: 600;
}
.min_max_btn {
  display: inline;
  font-size: 14px;
  color: #4a4a4a;
  font-family: "proxima-nova", sans-serif !important;
  border: 1px solid #707070;
  background: #fff;
  padding: 2px 12px;
}

.hr-tag {
  margin-right: -22px;
  margin-left: -20px;
  margin-top: -10px;
}
.filter_button {
  display: inline;
  font-size: 14px;
  border: solid 1px #5fa6e7;
  background-color: #fff;
  color: #2e8cd3;
  padding: 2px 17px;
}

.show-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  line-height: 2.6;
  text-align: left;
  color: #222838;
}
img:hover {
  color: #fff !important;
}

.stock-filter ::v-deep .form-control{
  height: 38px !important;
}
.stock-filter ::v-deep .lable-name{
  font-size: 12px;
}
.pagination-section ::v-deep .pagination .page-item .page-link {
  font-size: 25px;
  height: fit-content;
}
.pagination-section ::v-deep .pagination .active .page-link {
  font-size: 14px !important;
  padding: 11px 7px;
}
.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  top:0;
  /* z-index: 2; */
}
.pagination-class{
  display: flex;
  margin-left: 7px;
}

@media screen and (max-width: 767.98px){
  .stock_inventory{
    margin-top: 25px;
  }
}

@media screen and (max-width: 405px){
  .pagination-class{
    display: flex;
    /* margin-top: 7px; */
    margin-left: 0;
  }
}
@media screen and (max-width: 430px){
  .filter-with-pagination{
    flex-direction: column;
  }
  .pagination-section{
    margin-left: 3px;
    margin-top: 5px;
  }
}
@media only screen and (max-width: 340px){
  .pagination-section ::v-deep .page-link{
    min-width: 10px !important;
  }
}
</style>
