<template>
  <div class="upload-shipment">

    <div class="row mx-2 mt-5 px-2 d-flex justify-content-between align-items-center">
      <div class="my-3 upload-heading">Upload Template</div>
      <div
        class="my-3" role='button'
        @click="goBackToPreviousPage"
      >
        <img src="../../assets/CloseModel.svg" width="20px" height="20px"> 
      </div>
    </div>

    <div class="m-2" :body-class="'card-padding'">
      <p class="mb-1 ml-2 upload-subheading">Upload Template</p>
      <div class="row px-2">
        <div class="col-lg-4 col-md-5 col-12">
          <b-form-file
            id="upload-listing"
            v-model="selectedFile"
            accept=".xlsx"
            :placeholder="`Drop your Files here or click to upload Supports .XLS`"
          ></b-form-file>
        </div>
        <div class="col-lg-4 col-md-7 col-12 d-flex align-items-end">
          <w-button-input 
            :isDisabled="isSubmitBtnDisabled"
            :buttonClass="'auth-button'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="uploadListingTemplate()"
            :label="'Upload'"
          />
          <w-button-input 
            class="ml-3"
            :buttonClass="'request-demo'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="cancelUploadTemplate()"
            :label="'Cancel'"
          />
        </div>
      </div>
    </div>

    <div class="m-2 upload-table pb-5" :body-class="'card-padding'">
      <w-shipmentStatus-table
        :tableLoading="tableLoading"
        :tableProperties="tableProperties"
        :hideDownloadForListing= "false"
        @getSelectedPerPageEvent="getSelectedPerPage($event)"
        @getSelectedPageEvent="getSelectedPage($event)"
        @processingReportClicked="processingDownloadClick($event)"
      />
    </div>

    <!-- error modal  -->
    <w-loading-spinner
      :loadSpinner="isloadingWidget"
      :isSchedulePickup="true"
    />
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import ButtonInput from "../../widgets/InputButton.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import Modal from "../../widgets/ModalWidget";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import {
  getUploadListingTable,
  downloadProcessingReport,
  uploadTemplate,
} from "../../Service/InventoryService.js";

export default {
  components: {
    "w-button-input": ButtonInput,
    "w-shipmentStatus-table": TableWithCheckbox,
    Modal,
    "w-loading-spinner": LoadingSpinner,
  },
  data() {
    return {
      companyID:sessionStorage.getItem("companyIds"),
      tableLoading: false,
      isloadingWidget: false,

      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      selectedFile: null,
      errorMsg: "",

      tableProperties: {
        fields: [
          {
            key: "createdDate",
            label: "Date and Time",
          },
          {
            key: "batchId",
            label: "Batch ID",
          },
          {
            key: "totalSuccessfulMapping",
            label: "Total Successful Mapping",
          },
          {
            key: "totalFailureMapping",
            label: "Total Failure Mapping",
          },
          {
            key: "action_shipment",
            label: "Action",
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
    };
  },

  created() {
    this.getUploadTemplateTable();
  },

  computed: {
    isSubmitBtnDisabled() {
      if (!this.selectedFile == "") {
        return false;
      }
      return true;
    },
  },

  methods: {

    getUploadTemplateTable() {
      this.tableLoading = true;
      getUploadListingTable(
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage
      )
        .then((res) => {
          console.log("upload", res);
          this.tableLoading = false;
          this.tableProperties.items = res.content;

          this.tableProperties.totalRows = res.totalElements;
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    cancelUploadTemplate(){
      this.selectedFile = "";
    },

    //    upload files
    uploadListingTemplate() {
      this.isloadingWidget = true;
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("companyId", "7");

      uploadTemplate(formData, this.companyID)
        .then((res) => {
          this.isloadingWidget = false;
          this.alert = true;
          this.alertMsg = res.response || "Successfully Uploaded";
          this.alertType = "Success";
          this.isConfirmAlert = false;

          this.selectedFile = "";
          this.getUploadTemplateTable();
        })
        .catch((err) => {
          this.isloadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message || "Oops something went wrong?";
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // download processing report file
    processingDownloadClick(batchID) {
      this.isloadingWidget = true;
      downloadProcessingReport(batchID)
        .then((res) => {
          this.isloadingWidget = false;
        })
        .catch((err) => {
          this.isloadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message || "No message available";
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getUploadTemplateTable();
    },

    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getUploadTemplateTable();
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // for navigation
    goBackToPreviousPage() {
      this.$emit("goBackToPrevPage", false);
    },
    
  },
};
</script>

<style scoped>
.upload-shipment{
  background: #fff;
  box-shadow: 0px 1px 3px #00000029
}
.upload-heading{
  color: #1B1C1D;
  font-size: 18px;
  font-weight: 600;
}
.upload-subheading{
  color: #767676;
  font-size: 12px;
}
.upload-shipment ::v-deep .custom-file-label::after{
  display: none;
}
.upload-shipment ::v-deep .custom-file{
  height: 120px;
}
.upload-shipment ::v-deep .custom-file-label{
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5 0% 0% no-repeat padding-box;
}
.upload-shipment ::v-deep .form-file-text{
  color: #AEAEAE;
  font-size: 12px;
  width: 210px;
  text-align: center;
}
.upload-shipment ::v-deep .request-demo{
  background: #fff;
  border: 1px solid #005D84;
  padding: 0.5rem !important;
}

.upload-btn {
  background-color: white;
  border: 1px solid #2e8cd3;
  padding: 7px 40px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: left;
  color: #2e8cd3;
  height: 32px;
  border-radius: 2px;
}

.upload-table ::v-deep table thead tr th {
  text-align: center !important;
}
.upload-table ::v-deep table tbody tr td {
  text-align: center !important;
}
.upload-table ::v-deep table thead tr th:first-child {
  text-align: left !important;
}
.upload-table ::v-deep table tbody tr td:first-child {
  text-align: left !important;
}
::v-deep .custom-file-input {
  cursor: pointer;
}

@media screen and (max-width : 768px) {
  .upload-shipment{
    margin: 40px 20px 0 20px;
  }
  .upload-shipment div:first-child{
    margin-top: 0 !important;
  }
}
</style>
