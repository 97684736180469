<template>
  <div>
    <b-tabs
      content-class="mt-0"
      ref="tabs"
      class="report-tabs"
      v-model="tabIndex"
      align="center"
    >
      <b-tab title="STOCK" class="stock" @click="getActiveTab('stock')" v-if="permission[0].isPermission" active >
        <Stock ref="stockTab" :isGetData="getDataTab === 'stock'" />
      </b-tab>
      <b-tab title="LISTING STATUS" class="listing" @click="getActiveTab('listingstatus')" v-if="permission[1].isPermission" >
        <ListingStatus ref="listingTab" :isGetData="getDataTab === 'listingstatus'" />
      </b-tab>
      <b-tab title="LOW STOCK LIMIT RESET" class="listing" @click="getActiveTab('lslReset')" v-if="permission[1].isPermission" >
        <!-- <ListingStatus ref="listingTab" :isGetData="getDataTab === 'listingstatus'" /> -->
        <div class="mt-4 mr-2"><button class="d-block ml-auto btn btn-outline-primary" @click="onUploadTemplate()">Upload Template</button></div>
        <b-card class="mt-4 cards m-2 listing-table listing-status-table lsl-table-card">
          <w-table :tableData="lslTableData"></w-table>
        </b-card>        
      </b-tab>
    </b-tabs>

    <b-modal
      id="upload-lsl-template"
      size="xl"
      hide-footer="true"
      hide-header-close
      centered
    >
      <w-UploadEasyShipTemplate
        @goBackToPreviousPage="goBackToPreviousPage('easyshipTemplate')"
      />
    </b-modal>
  </div>
</template>

<script>
import ReportsTable from "../widgets/ReportsTable.vue";
import ListingStatus from "../components/Inventories/ListingStatus.vue";
import Stock from "../components/Inventories/Stock.vue";
import { isAuthorized } from "../Service/CommonService";
import UploadShipment from "../components/Shipments/UploadShipment.vue";
export default {
  components: {
    "w-table": ReportsTable,
    "w-UploadEasyShipTemplate": UploadShipment,
    ListingStatus,
    Stock,
  },
  data() {
    return {
      tabIndex: 0,
      getDataTab:"",
      lslTableData: {
        fields: [
          {
            key: "masterSku",
            label: "Master SKU",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
          },
          {
            key: "masterTitle",
            label: "Master Title",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
          },
          {
            key: "currentLslValue",
            label: "Current LSL Value",
            thClass: "thClass",
            tdClass: "tdClass",
            class: "text-center",
          },
        ],
      },  
      permission:[
        {
          title:'stock',
          isPermission:isAuthorized('allow_view_entire_stock')
        },
        {
          title:'listing',
          isPermission:isAuthorized('allow_listing_status')
        }
      ]
    };
  },

  created(){
    if(isAuthorized('allow_view_entire_stock')){
      this.getDataTab = "stock"
    }else if(isAuthorized('allow_listing_status')){
      this.getDataTab = "listingstatus"
    }
  },

  methods:{
    getActiveTab(tabName){
      this.activeTab = tabName;
      if (this.activeTab === "stock") {
        this.$refs.stockTab.getData();
      }
      else if (this.activeTab === "listingstatus") {
        this.$refs.listingTab.getData();
      } else if (this.activeTab === "lslReset") {
        this.getLslData();
      }
    },
    getLslData() {
      console.log("getLslData call!  -- TODO: need to make API call to get LSL data.");
    },
    onUploadTemplate() { 
      console.log('onUploadTemplate triggered!!!');     
      this.$bvModal.show("upload-lsl-template");
    }
    
  },
};
</script>

<style>
.lsl-table-card > .card-body {
  padding:1.25rem 1rem !important;
}
.lsl-table-card .table-bordered {
  border: 1px solid #dee2e6 !important;
  margin-bottom: 0 !important;
}
.report-tabs .nav-tabs {
  padding-left: 15px;
}
.nav-tabs .nav-item {
  padding-right: 40px;
}
.report-tabs .nav-tabs .nav-item a {
  font-family: "proxima-nova", sans-serif;
  font-size: 16px;
  color: darkgray;
  font-weight: 500;
  padding-bottom: 15px;
}
.report-tabs .nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  border-bottom: 3px solid #2185d0 !important;
  border-color: transparent;
  color: #2185d0;
}
.report-tabs .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}

@media only screen and (max-width: 768px){
  .report-tabs .nav{
    border-bottom: 1px solid #dee2e6 !important;
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 480px){
  .report-tabs .nav{
    justify-content: center !important;
  }
}
</style>
